<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'my-leaves' }"> Leaves</router-link>
        </li>
        <li class="breadcrumb-item active">
          {{ filterString.replace(/^./, filterString[0].toUpperCase()) }} Leaves
        </li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">
            {{ filterString.replace(/^./, filterString[0].toUpperCase()) }}
            Leaves
          </h2>
        </div>
        <div class="col-md-1 text-right">
          <!-- <router-link
            :to="{ name: 'request-leave' }"
            class="btn btn-outline-primary"
            >Request Leave</router-link
          > -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="this.$store.state.loader">
            <loader
              object="#f74b3d"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              disableScrolling="false"
              name="spinning"
            ></loader>
          </div>
          <div v-else class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <div v-if="this.$store.state.loader">
                <loader
                  object="#f74b3d"
                  color1="#ffffff"
                  color2="#17fd3d"
                  size="5"
                  speed="2"
                  bg="#343a40"
                  objectbg="#999793"
                  opacity="80"
                  disableScrolling="false"
                  name="spinning"
                ></loader>
              </div>
              <div v-else>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="leavesData.length"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
                <b-table
                  class="table table-custom"
                  :items="leavesData"
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  sticky-header
                >
                  <template v-slot:cell(from)="data">
                    {{ data.item.leavefrom }}
                  </template>
                  <template v-slot:cell(to)="data">
                    {{ data.item.leaveto }}
                  </template>
                  <template v-slot:cell(return_date)="data">
                    {{ data.item.returndate }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <a
                      v-if="
                        data.item.status != 'Cancelled' &&
                        data.item.status != 'Approved'
                      "
                      class="button button1"
                      title="Cancel leave"
                      @click.prevent="cancelLeave(data.item.id)"
                      ><i class="icon-cancel-circle"></i
                    ></a>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      filterString: this.$route.params.filterString,
      perPage: 15,
      currentPage: 1,
      fields: [
        {
          key: "type",
          label: "Leave Group",
        },
        {
          key: "leave_type",
          label: "Leave Type",
        },
        {
          key: "from",
          label: "Leave From",
        },
        {
          key: "to",
          label: "Leave To",
        },
        {
          key: "start_time",
          label: "Start time",
        },
        {
          key: "end_time",
          label: "End Time",
        },
        {
          key: "reason",
        },
        {
          key: "return_date",
          label: "Return Date",
        },
        {
          key: "status",
        },
        {
          key: "action",
        },
      ],
    };
  },
  computed: {
    leavesData() {
      return this.$store.state.filteredMyLeaves;
    },
  },
  created() {
    var token = localStorage.getItem("token");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadMyFilteredLeaves", this.filterString);
  },
  methods: {
    cancelLeave(id) {
      if (confirm("Are you sure, you want to cancel leave?")) {
        axios.post("/personal/leave/cancel", { id: id }).then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
          }
          this.$store.dispatch("loadMyLeaves");
        });
      }
    },
  },
};
</script>
